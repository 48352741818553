import React from 'react'
import styles from '../styles/Tarif.module.css'

const Tarif = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Наші тарифи </h1>
      <ul className={styles.tarifList}>
        <li className={styles.tarifItem}>
          <div className={styles.speed}>GEPON-100(100 Мб/с)</div>
          <div className={styles.price}>230 грн/міс</div>
        </li>
        <li className={styles.tarifItem}>
          <div className={styles.speed}>GEPON-300(300 Мб/с)</div>
          <div className={styles.price}>300 грн/міс</div>
        </li>
        <li className={styles.tarifItem}>
          <div className={styles.speed}>Статичний IP</div>
          <div className={styles.price}>400 грн/міс</div>
        </li>
      </ul>
    </div>
  )
}

export default Tarif
