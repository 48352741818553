
import styles from '../styles/About.module.css'


const About = () => {
  return (
    <>
  <h2>Наші контакти:</h2>
  
   <ul className={styles.list}>
    <li> <b>м.Суми вул.Новоміської сотні,1(Рибалко) </b> </li>
    <li><b>тел.050 634 80 93</b></li>
    <li><b>info@online.sumy.ua</b></li>
   </ul>

</>

  )
}

export default About